@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../../public/assets/fonts/Inter/Inter-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "InterBold";
  src: url("../../public/assets/fonts/Inter/Inter-Bold.ttf");
  font-style: normal;
}

@font-face {
  font-family: "InterMedium";
  src: url("../../public/assets/fonts/Inter/Inter-Medium.ttf");
  font-style: normal;
}

html{
  background-color: #F8F7F6;
}
h2 {
  font-family: InterBold;
  color: #2C2A2E;
}

p {
  font-family: Inter;
  color: #2C2A2E;
}

span {
  font-family: Inter;
  color: #FFFF;
}

button {
  font-family: InterMedium;
  color: #FFFF;
}